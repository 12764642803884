import { Box, Button, TextField, Typography} from "@mui/material"
import { createUserWithEmailAndPassword, getAuth, updateProfile } from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./AuthBoxes.css"

export default function CreateAccountBox({setErrored}) {
    function createAccount(email, password, navigate) {
        createUserWithEmailAndPassword(getAuth(), email, password)
          .then((result) => {
            updateProfile(result.user, {
                displayName: username
            })
            navigate("/startJourney")
          })
          .catch((error) => {
            console.log(error)
            if (!!error.code) {
                setErrored(error.code.split("/")[1])
            }
          });
    }

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');

    const navigate = useNavigate()

    return (
        <>
            <Typography variant="h6">Create Account</Typography>
            <Box className="inputs-container">
                <TextField 
                    required 
                    label="username" 
                    variant="standard" 
                    className="text-input"
                    value={username}
                    onChange={(event) => setUsername(event.target.value)}
                />
                <TextField 
                    required 
                    label="email" 
                    variant="standard" 
                    className="text-input"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                />
                <TextField 
                    required 
                    label="password" 
                    variant="standard" 
                    type="password" 
                    className="text-input"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                />
            </Box>
            <Box className="buttons-box">
                <Box sx={{flex: 1}}/>
                <Button 
                    variant="contained" 
                    size='small' 
                    onClick={() => createAccount(email, password, navigate)}
                >
                    Create Account
                </Button>
            </Box>
        </>
    )
}