import { Button } from "@mui/material";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";


export default function StartButton() {
    const navigate = useNavigate()

    const [isLoggedIn, setIsLoggedIn] = useState(!!getAuth().currentUser);
    useEffect(() => {
        onAuthStateChanged(getAuth(), (user) => {
          setIsLoggedIn(!!user)
        })
      }, [])
    
    const onClick = () => isLoggedIn ? navigate("/startJourney") : navigate("/auth")

    return (
        <Button
            onClick={onClick}
            sx={{
                backgroundColor: "#B59410",
                color: 'white',
                "&:hover" : {backgroundColor: '#C5A939'},
                padding: 3
            }}
        >
            {isLoggedIn ? "Continue Your Journey" : "Start Your Journey"}
        </Button>
    )
}