import firebase from "firebase/compat/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyA187vqZlNCxXVihzGMS57iY2TVc5c6WCc",
  authDomain: "bain-dnd.firebaseapp.com",
  projectId: "bain-dnd",
  storageBucket: "bain-dnd.appspot.com",
  messagingSenderId: "980767091551",
  appId: "1:980767091551:web:42caa5ac7d00b14278e4ea"
};

const app = firebase.initializeApp(firebaseConfig);

const db = getFirestore()

export {app, db}