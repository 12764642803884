import { Box, Button, TextField, Typography} from "@mui/material"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./AuthBoxes.css"

export default function LogInBox({setErrored}) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    function login(email, password, navigate) {
        signInWithEmailAndPassword(getAuth(), email, password)
          .then((_) => {
            navigate("/startJourney")
          })
          .catch((error) => {
            console.log(error)
            setErrored(error.code.split("/")[1])
          });
    }

    const navigate = useNavigate()

    return (
        <>
            <Typography variant="h6">Log In</Typography>
            <Box className="inputs-container">
                <TextField 
                    required 
                    label="email" 
                    variant="standard" 
                    className="text-input"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                />
                <TextField 
                    required 
                    label="password" 
                    variant="standard" 
                    type="password" 
                    className="text-input"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                />
            </Box>
            <Box className="buttons-box">
                <Box sx={{flex: 1}}/>
                <Button variant="contained" size='small' onClick={() => login(email, password, navigate)}>Log in</Button>
            </Box>
        </>
    )
}