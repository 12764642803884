import { Box, Typography, Container, Button } from "@mui/material"
import CustomAppBar from "../CustomAppBar"
import { useNavigate } from "react-router-dom"

export default function Home() {
    const navigate = useNavigate()

    const buttonRoute = (route, text, disabled=false) => {
        return (
            <Button
                variant="contained"
                size="medium"
                onClick={() => navigate(route)}
                disabled={disabled}
            >
                {text}
            </Button>
        )
    }
    return (
        <Box className="background">
            <CustomAppBar/>
            <Container className="content" sx={{textAlign: 'center'}}>
                <Typography variant="h4">Home</Typography>
                <Typography variant="body1">I haven't finished this yet, but I will! In the meantime, feel free to look at the other pretty screens I already made.</Typography>
                <Box sx={{padding: 5, gap: 5, display: "flex", flexDirection: "column"}}>
                    {buttonRoute("/", "Main Screen")}
                    {buttonRoute("/startJourney", "Start Journey Screen")}
                    {buttonRoute("/home", "Delegate Dossiers", true)}
                </Box>
            </Container>
        </Box>
    )
}