const kingdomData = [
    {
        name: '',
        bgColor: "",
        description: "Select a kingdom"        
    },
    {
        name: 'Logica',
        bgColor: "rgb(130, 200, 130)",
        description: "The kingdom Logica is a society where scholars and artificers meticulously dissect the arcane arts. They have carved a city in the snow-peaked Northern Mountains where knowledge is power, and the intricate designs of spells and homunculi are valued above all else. While friends and foes may come and go, the scientific core of arcane design is something never changing, making it of timeless value." + 
        "\n\nLogica operates under a rigorous intellectual meritocracy. Every five years, the nation braces for the Arcane Evaluation, a series of assessments that identifies the kingdom's most exceptional minds. Elven wizards, with their centuries of experience, have consistently risen to the top. While their dominance is undeniable, it stems partly from their tight control over high-level spellcasting secrets. They insist they would be willing to share their knowledge, if only someone could offer something of equal value." + 
        "\n\nThe Logican government is resentful of less rigorous forms of magic such as sorcery and bardic songs. Worse even are those who flimsily are given magic by deities or through warlock pacts. The kingdom has made it clear that they want a world governed only by rational decision making and the structured magic of wizards and artificers.",
        relationship: "Logica's unwavering belief in the superiority of structured magic creates tension with the other kingdoms. Their blunt, logic-driven approach to diplomacy often lands poorly. They dismiss the bardic magic of Aurea and the divine blessings of Viges as fanciful. Similarly, they view Fortis' reliance on strength and Endura's defensive tactics as overly simplistic and lacking in refinement. Only Umbria finds some respect in Logica's eyes, thanks to the disciplined monks and the meticulously trained rogues, both reflecting a dedication to honed skills, albeit in a less arcane form."
        
    },
    {
        name: 'Viges',
        bgColor: "#85c0ea",
        description: "Deep within the Enchanted Forest lies Viges, a kingdom where clerics, druids, and rangers live in harmony with nature. Vine-woven temples rise amidst ancient trees, their halls echoing with the prayers of the faithful and the gentle murmur of the forest creatures. The people of Viges gather sustenance from the land, harvesting berries, fishing for salmon in the crystal-clear rivers, and hunting small game that darts through the leafy canopy. Here, violence is a last resort, replaced by a deep respect for nature and the divine." +
        "\n\nThough slow to anger, the Vigen people are decisive in their actions. When conflict arises, they stand united, a formidable force backed by the blessings of the gods, the fury of the forest itself, and the skilled rangers who guard their borders. Vigen children are raised to understand the interconnectedness of all things – faith in the divine, respect for the environment, and the essential skills for survival. As they mature, they naturally gravitate towards a path that resonates with them, becoming reverent clerics, wise druids, or adept rangers." +
        "\n\nAn incredibly diplomatic people, Viges has survived not by brute force, but rather through careful decision making. Their government is a harmonious democracy. Each class – clerics, druids, and rangers – forms a elects a representative to voice their concerns. These three representatives form the Vigen Council, making decisions for the kingdom through majority vote.",
        relationship: "Viges approaches inter-kingdom affairs with cautious pragmatism. Alliances, for them, are strategic maneuvers, not expressions of friendship. Their keen understanding of the world compels them to view Logica's arcane prowess as the greatest potential threat. Conversely, Aurea's diplomatic finesse resonates with Viges' own peaceful nature, making them natural allies. The remaining kingdoms – Fortis, Endura, and Umbria – lack the potent arcane power to be considered a serious threat, leading them to maintain a neutral stance."
    },
    {
        name: 'Aurea',
        bgColor: '#f7d30d',
        description: "Bathed in the golden glow of Artesia's setting sun, Aurea basks on the continent's glistening coastline. Renowned as the most aesthetically pleasing kingdom, Aurea boasts opulent houses adorned with treasures plucked from the ocean depths. Here, eloquent bards weave captivating tales, while warlocks and sorcerers ignite the night with dazzling magic displays." +
        "\n\nHowever, beneath the dazzling veneer lies a society where social credit gleams brighter than gold. While wealth garners respect, it's the whispered secrets of neighbors that hold true power. The elegant-seeming facade masks a society rife with devilish pacts and untamed wild magic, a testament to the Aurean penchant for unbridled ambition." + 
        "\n\nDespite the inherent greed that threatens to unravel the kingdom, Aurea endures thanks to its unwavering paladins. These oath-bound warriors, paragons of law and order, have enough charisma to hold their own against even the most cunning Aurean schemer. Though Aurea lacks a formal government, a delicate balance exists: social status is fiercely guarded, and the ever-present threat of paladin intervention keeps the kingdom in check.",
        relationship: "Aurea believes in maintaining their status as the fanciest, most prized kingdom in all of Artesia. Aurea utilizes their sharpest tongue when discussing with Viges while opting flattery with Endura, Fortis, and Umbria. Despite their desire to maintain a positive relationship with everyone, they understand that working with Logica would actively harm their perception. Publicly, they scoff at the Logican wizards, but whispers abound of secret negotiations, where Aurea seeks to assuage Logica's concerns and maintain a facade of friendly neutrality."
    },
    {
        name: 'Umbria',
        bgColor: "rgb(180, 180, 180)",
        description: "Umbria lies within the caverns that honeycomb the Northern Mountains, nestled directly below the kingdom of Logica. This secretive kingdom has become a magnet for aspiring and dedicated warriors, drawing those who crave the honed skills and ruthless efficiency that Umbrian training offers. Even the monks who dwell in monasteries carved into the sie of the mountain hone are far from peaceful, always prepared to use their skills to protect the monasteries." +
        "\n\nWhile some still hold aspirations of gaining power, most citizens are simply stealing for a meal to eat at the end of the day. The unforgiving caverns offer no fertile ground for crops, and fresh water is a precious commodity. Here, life is a constant struggle, forcing citizens to rely on their cunning and agility to secure food and resources. The constant threat of the Underdark, a vast subterranean network rumored to teem with monstrous creatures, adds another layer of danger to Umbria's precarious existence." +
        "\n\nDecades of political turmoil, marked by assassinations and internal rebellions, have led its citizens to a grim conclusion: true leadership often led to a swift and suspicious demise. As a consequence, Umbria is controlled by a shadow government, where power rests with a group of unseen figures who carefully orchestrate events from behind the scenes. They prop up weak and easily manipulated leaders, ensuring any potential for a strongman is swiftly quashed.",
        relationship: "Unlike Viges, Umbria makes no claims to be honorable or trustworthy. They openly publicize the cynical belief that everyone, including themselves, acts solely out of self-interest. Umbria will work with kingdoms insofar as it serves them, which currently seems to be Logica. They find the fighting styles of Endura and fortis to be amateurish, lacking a certain finesse. Umbria trusts only in the tangible strength and cunning honed within their harsh environment, viewing the reliance of other kingdoms on magic and divine blessings as weak."
    },
    {
        name: 'Endura',
        bgColor: '#D8BFD8',
        description: "Encased within three layers of formidable walls, the Enduran stronghold stands defiant in the heart of the scorching southern desert. The sun's merciless heat reigns supreme during the day, giving way to bone-chilling nights. The harsh environment itself serves as the first line of defense, sapping the strength and resources of any would-be invader. This unforgiving landscape mirrors the spirit of the Enduran soldier - a testament to resilience and unwavering resolve." +
        "\n\nHonor is the cornerstone of Enduran warfare. Dodging blows is considered cowardly, a stark contrast to the tactics employed by their rivals in Umbria. This unwavering commitment to constitution in combat often leads Enduran warriors to forgo armor or shields, insisting that they can withstand any blow inflicted on them. Against magic-wielders, their strategy is one of attrition. By outlasting their opponents' magical reserves, Enduran soldiers eventually seize victory over the exhausted foe." +
        "\n\nEnduran politics, like their military, are a test of patience and discipline. A strict hierarchy governs society, with positions earned through a lifetime of service. When a vacancy arises, a challenge is agreed upon by all direct subordinates who challenge for the position. King Relic, a Warforged who fought in the Sundering of Artesia firsthand, has ruled since the kingdom's very foundation, his unwavering leadership a symbol of Endura's unwavering spirit.",
        relationship: "Endura holds Umbria in open contempt. Their reliance on subterfuge and hit-and-run tactics clashes with the Enduran code of honor in battle. Fortis, on the other hand, holds Endura’s respect. While their focus differs, both prioritize physical strength and resilience, fostering a sense of camaraderie between the two desert kingdoms. Families with dual citizenship are not uncommon, a testament to the shared values between these warriors. The kingdoms of Viges, Aurea, and Logica spark little interest in Endura. Their reliance on magic and diplomacy falls outside the Enduran purview, leading to limited interaction."
    },
    {
        name: 'Fortis',
        bgColor: '#e58b8b',
        description: "Fortis thrives amidst a fiery cradle – a chain of volcanic islands sculpted by molten lava in Artesia's westernmost reaches. This breathtaking yet perilous landscape defines the Fortis people. Entire villages can vanish in a single night, consumed by the capricious wrath of the volcanoes. Yet, the Fortish people view such sacrifices as offerings to Kossuth, the deity who holds dominion over their fiery home." +
        "\n\nAdmiring the lava, the Fortish people revere raw power. They channel the destructive might of the volcanoes into their combat style, believing in brute force to overcome any obstacle. Their tactics are straightforward: overwhelm the opposition with sheer, unrelenting strength. Fortish society is divided into two distinct but equally valued groups: warriors and caretakers. Warriors recognize that their power stems from the nourishment and support provided by the diligent caretakers." +
        "\n\nGovernment is simple: the strongest warrior, through a public challenge, ascends the throne. This constant testing ensures that the ruler remains a formidable figure. Interestingly, all non-military affairs are overseen by the current ruler's caretaker. The logic follows: the caretaker who nurtured the strongest warrior must possess the skills to manage the kingdom's internal matters. However, the current ruler, weary of endless duels for power, has instituted a \"death-match only\" policy, significantly reducing the frequency of challenges.",
        relationship: "Fortis has a well-established relationship with Endura. While they prioritize strength, they still see value in the ability to endure hits. Fortis views Umbria's reliance on stealth and subterfuge with disdain, considering it a sign of weakness. Magic users, particularly those of Viges, Aurea, and Logica, hold little appeal for the Fortis people. Their reliance on \"roundabout methods\" of combat clashes with the Fortis belief in direct confrontation. However, they grudgingly acknowledge Logica's meritocratic system, where power determines leadership - a concept they can understand."
    }
    ]

export {kingdomData}