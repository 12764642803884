import { useState } from 'react'
import { getAuth } from 'firebase/auth';
import './Auth.css'
import LogInBox from './LogInBox';
import CreateAccountBox from './CreateAccountBox';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function Auth() {
    
    const [creatingAccount, setCreatingAccount] = useState(false)
    const [errored, setErrored] = useState("")

    const isLoggedIn = !!getAuth().currentUser

    const navigate = useNavigate()


    const signOutBox = (
        <Box flexDirection={'column'} display={'flex'} gap={5}>
            <Typography>You're already signed in. Are you sure you want to sign out?</Typography>
            <Button onClick={() => getAuth().signOut()} variant="contained">Log Out</Button>
            <Button onClick={() => navigate("/home")} variant="contained">Go Home</Button>
        </Box>
    )

    const signInBox = <LogInBox setErrored={setErrored}/>

    const createAccountBox = <CreateAccountBox setErrored={setErrored}/>

    const toggleBoxData = creatingAccount ? (
        <Box className="bottom-text-block">
            <Typography variant="body1">Already have an account?</Typography>
            <Button variant="text" onClick={() => setCreatingAccount(false)} size='small'>Log In</Button>
        </Box>
        ) : (
        <Box className="bottom-text-block">
            <Typography variant="body1">First time?</Typography>
            <Button variant="text" onClick={() => setCreatingAccount(true)} size='small'>Create Account</Button>
        </Box>
        )
    
    const errorDisplay = !!errored ? <Typography color="error">Login Error. Please check your email and password. Reach out to me if you're having problems.</Typography>: <></>

    return (
        <div className='auth-background'>
            <div className='filler-box'/>
            <Box className='auth-box my-blurred-div'>
                {isLoggedIn ? signOutBox: (creatingAccount ? createAccountBox : signInBox)}
                {errorDisplay}
                {isLoggedIn ? <></> : toggleBoxData}
            </Box>
            <div className='filler-box'/>
            <div className='filler-box'/>
        </div>
    )
  }