import { Typography } from "@mui/material";


export default function WorldData() {
    const worldDescription = "Millennia ago, the plane of Artesia was overrun by the terror of dragons and monsters that stalked the night. Elves, dwarves, humans, and other smaller races were bound by a desperate alliance. They honed their skills, forged weapons, and even dabbled in the arcane arts, all to carve a fragile existence out of the nightmarish world." +  
    "\n\nSlowly, the tide turned. The monstrous inhabitants were driven into the shadows and dragons were all but exterminated, leaving behind a hard-won peace. But peace, it seemed, bred its own demons. Magic, once a unifying force, became a point of contention. Erudite scholars scoffed at the pacts made by warlocks, and the eloquent bards found their voices drowned by the clash of steel favored by warriors." + 
    "\n\nThis simmering discontent erupted in an event known now as The Sundering of Artesia, an uprising that fractured Artesia into six warring kingdoms. Each, an echo of one element of Artesia: Logica, a haven for arcane scholars and crafters; Viges, the land  of clerics and druids; Aurea, home of the silver-tongued bards; Umbria, the domain of shady rogues and calm monks; Endura, the unyielding desert stronghold; and Fortis, a land where brute force reigns supreme." + 
    "\n\nA thousand years have passed. Uneasy peace hangs heavy. War hasn't begun, but not for lack of desire. Each kingdom lacks the power to conquer the others. Should that change, a devastating war would erupt, consuming Artesia's fragile unity."
    return (
        <>
            <Typography variant="h3">The World of Artesia</Typography>
            <Typography variant="body1" sx={{whiteSpace: "pre-wrap", textAlign: 'left'}}>{worldDescription}</Typography>
        </>
    )

}