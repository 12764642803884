import {BrowserRouter, Route, Routes} from "react-router-dom";
import LandingPage from "./landingPage/LandingPage";
import Auth from "./auth/Auth";
import Home from "./home/Home";
import StartJourney from "./startJourney/StartJourney";
import { ThemeProvider, createTheme } from "@mui/material";

export const themeOptions = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#2E4C33',
    },
    secondary: {
      main: '#B2C7B4',
    },
    background: {
      default: '#efebe9',
    },
  }
})

function App() {

  return (
    <ThemeProvider theme={themeOptions}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage/>}/>
          <Route path="/home" element={<Home/>}/>
          <Route path="/auth" element={<Auth/>}/>
          <Route path="/startJourney" element={<StartJourney/>}/>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App;
