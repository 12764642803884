import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { app } from "../firebase-config.js";
import { Box, Container, Collapse, IconButton, Typography, Card } from "@mui/material";
import CustomAppBar from "../CustomAppBar.js";
import KingdomTabs from "../KingdomTabs.js";
import {ExpandMore, ExpandLess} from '@mui/icons-material';
import "./StartJourney.css"
import SelectableTokens from "./SelectableTokens.js";


function SectionCollapse({ title, children, initialExpanded = false }) {
  const [expanded, setExpanded] = useState(initialExpanded);
  
  const handleExpandClick = () => {
      setExpanded(!expanded);
  };
  
  return (
      <Card elevation={3} sx={{marginTop: 3}}>
          <Box className="collapse-header">
              <IconButton
                  size="medium"
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show text"
                  sx={{padding: 0}}
              >
                  {expanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
              <Typography variant="h5">{title}</Typography>
          </Box>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
              {children}
          </Collapse>
      </Card>
  );
}

function JourneyHeader() {
  const journeyDescription1 = "Yesterday, each kingdom of Artesia was visited by a Gith warrior riding a red dragon who demanded to speak with the nation's leader."
  const journeyDescription2 = "Each Gith carried with them a scroll sealed by the crest of the Mind Flayer's Crown Prince."
  const journeyDescription3 = "The Mind Flayer Empire is legendary as one of the most powerful in all of the Astral Plane, a mysterious world adjacent to the material plane where Artesia lies. You are to be the delegate (12th level PC) for a kingdom of your choice. The information about each kingdom is available below to assist in your decision."
  return (
    <Box sx={{display: "flex", flexDirection: "column", textAlign: 'center'}}>
      <Typography variant="h4" sx={{justifySelf: 'center'}}>Journey Details</Typography>
      <Typography variant="body1">{journeyDescription1}</Typography>
      <Box sx={{display: "flex", flexDirection: "column", alignItems: 'center', paddingTop: 3, paddingBottom: 3}}>
        <Box sx={{display: "flex", justifyContent: "center", gap: 10}}>
          <img src={require(`./images/githOnDragon.png`)} alt="Gith on Dragon" className="gith-images" />
          <img src={require(`./images/githWarrior.png`)} alt="Gith Warrior" className="gith-images" />
        </Box>
      </Box>
      <Typography variant="body1">{journeyDescription2}</Typography>
      <Box sx={{display: "flex", flexDirection: "column", alignItems: 'center', paddingTop: 3, paddingBottom: 3}}>
        <img src={require(`./images/Enno_letter.png`)} alt="Enno's Letter" className="letter"/>
      </Box>
      <Typography variant="body1">{journeyDescription3}</Typography>
    </Box>
  )
}

function SelectYourKingdom() {
  return (
    <Box sx={{display: "flex", flexDirection: "column", marginTop: 3, textAlign: 'center'}}>
      <SelectableTokens/>
    </Box>
  )
}

export default function StartJourney() {
    const navigate = useNavigate()

    useEffect(() => {
      onAuthStateChanged(getAuth(app), (user) => {
        if (!user) {
          navigate("/auth");
        }
      });
    }, [navigate]);

    return (
      <Box className="background">
        <CustomAppBar/>
        <Container className="content">
          <JourneyHeader/>
          <SectionCollapse title={"Kingdoms"}><KingdomTabs/></SectionCollapse>
          <SelectYourKingdom/>
        </Container>
      </Box>
    )
}