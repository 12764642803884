import { Box, Button, Typography } from "@mui/material";
import "./SelectableTokens.css"
import { useState, useEffect } from "react";
import { db } from "../firebase-config";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { setDoc, doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const kingdomNames = ["Logica", "Viges", "Aurea", "Umbria", "Endura", "Fortis"]

export default function SelectableTokens() {
    const [selectedToken, setSelectedToken] = useState("")
    const [documentExists, setDocumentExists] = useState(false);
    const auth = getAuth()

    // Check for document existence on component mount and auth state change
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
            const docRef = doc(db, "Users", user.uid);
            const docSnap = await getDoc(docRef);
            setDocumentExists(docSnap.exists());
            setSelectedToken(docSnap.data().kingdom)
            }
        });
        return unsubscribe; // Cleanup function to prevent memory leaks
    }, [auth]);

    const tokens = (
        <Box className='token-container'>
            {kingdomNames.map((kingdom) => {
                return (
                    <div className="token" key={kingdom} onClick={() => setSelectedToken(kingdom)} >
                        <Typography variant="h5">{kingdom}</Typography>
                        <img 
                            src={require(`./images/${kingdom}Token.PNG`)} 
                            className={"tokenImg"} 
                            alt={kingdom + " Token"} 
                            key={kingdom}
                        />
                    </div>
                )
            })}
        </Box>
    )

    const navigate = useNavigate()

    const handleClick = () => {
        const docID = auth.currentUser.uid
        const docData = {
            username: auth.currentUser.displayName,
            kingdom: selectedToken
        }
        setDoc(doc(db, 'Users', docID), docData).then(() => {
            navigate("/home")
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          })
    }

    const selectKingdomButton = (
        <Button 
            variant="contained" 
            size="large" 
            title="Select Kingdom"
            disabled={!selectedToken}
            onClick={handleClick}
        >
            SELECT KINGDOM
        </Button>
    )

    const selectableContent = (
        <>
            <Typography variant="h4" sx={{marginBottom: 3}}>Select Your Kingdom</Typography>
            <Box sx={{gap: 5, flexDirection: 'column', display: 'flex'}}>
                {tokens}
                {selectedToken==="" ?
                <Typography variant="h5">Select a kingdom to continue</Typography> : 
                <Typography variant="h5">Your Kingdom: {selectedToken}</Typography>}
                {selectKingdomButton}
            </Box>
        </>
        )

    const notSelectableContent = (
        <Box sx={{flexDirection: 'column', display: 'flex'}}>
            <Typography variant="h4"> Your Kingdom: {selectedToken}</Typography>
            <Typography variant="body1"> You've already selected your kingdom.</Typography>
            <Button 
                variant="contained" 
                size="large"
                onClick={() => navigate("/home")}
            >
                Go Home
            </Button>
        </Box>
    )

    return documentExists ? notSelectableContent : selectableContent
}