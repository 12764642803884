import { Card, Paper, Typography, Box, Collapse, IconButton, CardActionArea, CardContent } from "@mui/material";
import { useState } from "react";
import "./KingdomTabs.css"
import {ExpandMore, ExpandLess} from '@mui/icons-material';

import { kingdomData } from "./kingdomData";

function TextCollapse({ title, text, initialExpanded = true }) {
    const [expanded, setExpanded] = useState(initialExpanded);
    
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    
    return (
        <div>
            <Box className="collapse-tab">
                <IconButton
                    size="medium"
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show text"
                    sx={{padding: 0}}
                >
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
                <Typography variant="h5">{title}</Typography>
            </Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Typography variant="body1" style={{whiteSpace: 'pre-line'}}>{text}</Typography>
            </Collapse>
        </div>
    );
}

export default function KingdomTabs() {
    const [displayed, setDisplayed] = useState("")

    const kingdomTitles = kingdomData.map((kingdom) => 
        kingdom.name==="" ? <div key={kingdom.name}/> :
            <Card 
                elevation={3} 
                className="card"
                sx={{"&:hover": {backgroundColor: kingdom.bgColor}, backgroundColor: displayed===kingdom.name ? kingdom.bgColor : ""}} 
                key={kingdom.name}
            >
                <CardActionArea onClick={() => setDisplayed(kingdom.name === displayed ? "" : kingdom.name)} className="paper-styling clickable">
                    <CardContent>
                        <Box className="card-name flex-el">
                            <Typography variant="h5">
                                {kingdom.name}
                            </Typography>
                        </Box>
                        <Box className="flex-el">
                            <img src={require(`./images/${kingdom.name}.png`)} className={"imgStyling"} alt="Kingdom Logo"/>
                        </Box>
                    </CardContent>
                </CardActionArea>
            </Card>
    )
    
    const kingdomExists = !!displayed
    const selectedKingdom = kingdomExists ? kingdomData.filter((kingdom) => kingdom.name === displayed)[0] : null

    return (
            <Box sx={{textAlign: "center"}}>
                {!kingdomExists && <Typography variant="h6">Click on a kingdom to learn more</Typography>}
                <Box className="title-selector">
                    {kingdomTitles}
                </Box>
                {kingdomExists && 
                    <Paper elevation={3} sx={{padding: 5, textAlign: 'left', backgroundColor: selectedKingdom.bgColor}}>
                        <Paper elevation={5} sx={{padding: 1, backgroundColor: selectedKingdom.bgColor, marginBottom: 2}}>
                            <TextCollapse text={selectedKingdom.description} title={"Description"}/>
                        </Paper>
                        <Paper elevation={5} sx={{padding: 1, backgroundColor: selectedKingdom.bgColor}}>
                            <TextCollapse text={selectedKingdom.relationship} title={"Relationships"}/>
                        </Paper>
                    </Paper>}
            </Box>
    )
}
