import {Toolbar, Typography, AppBar, Box, Button, Menu, MenuItem, IconButton} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import d20_icon from './d20_icon.svg';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useState, useEffect } from 'react';

export default function CustomAppBar() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!getAuth().currentUser);

  useEffect(() => {
    onAuthStateChanged(getAuth(), (user) => {
      setIsLoggedIn(!!user)
    })
  }, [])

  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState(null); // State for menu anchor

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
         <IconButton onClick={() => navigate('/')}>
            <img src={d20_icon} style={{width: 50, height: 50, marginRight: 5}} alt='d20 Dice'/>
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            D&D with Bain
          </Typography>
          {isLoggedIn ? 
            <>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls={anchorEl ? 'menu-profile' : undefined}
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircleIcon />
            </IconButton>
            <Menu
              id="menu-profile"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              MenuListProps={{ 'aria-labelledby': 'menu-profile' }}
            >
              <MenuItem onClick={() => navigate("/home")}>Home</MenuItem>
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>Settings</MenuItem>
              <MenuItem onClick={() => getAuth().signOut()}>Logout</MenuItem>
            </Menu>
          </>
            : 
            <Button color="inherit" onClick={() => navigate("/auth")}>Login</Button>
          }
        </Toolbar>
      </AppBar>
    </Box>
  );
}
