import { Container, Box, Typography } from "@mui/material";
import CustomAppBar from "../CustomAppBar";
import KingdomTabs from "../KingdomTabs";
import WorldData from "./WorldData";
import StartButton from "./StartButton";
import "./LandingPage.css"


export default function LandingPage() {

    return (
        <Box className="background2">
            <CustomAppBar/>
            <Container className="content">
                <Box sx={{display: 'flex', flexDirection: 'column', textAlign: 'center', gap: 2}}>
                    <WorldData/>
                    <Typography variant="h3">Kingdoms</Typography>
                    <KingdomTabs/>
                    <StartButton/>
                </Box>
            </Container>
        </Box>
    )
}